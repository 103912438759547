//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  middleware: 'guest',
  auth: false,
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    submitting: false,
    needsTwoFactor: false,
  }),
  methods: {
    async login() {
      this.submitting = true;
      try {
        await this.$auth.loginWith('apollo', {data: this.form});
        const options = {
          email: this.form.email,
        };
        this.$gtm.push({event: 'authenticate', ...options});
        await this.$router.push(this.localePath({name: 'index'}));
      } catch (err) {
        console.error(err);
        if (err.graphQLErrors.find(error => error.extensions.category === '2fa') && !this.form.two_factor_code) {
          this.needsTwoFactor = true;
          this.form.two_factor_code = null;
        } else {
          this.$toast.error(err);
        }
      } finally {
        this.submitting = false;
      }
    },
  },
};
